import React from "react";
import "./styles.css";

import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { red, lightBlue } from "@mui/material/colors";

import {
  // FormGroup,
  // FormControlLabel,
  // Switch,
  Box, CssBaseline
} from "@mui/material";

import { Navbar, Sidebar } from '../components'

const Layouts = () => {
  const [open, setOpen] = React.useState(true);

  const { user } = useSelector((state) => state.auth)
  const { mode } = useSelector((state) => state.app);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const theme = createTheme({
    palette: {
      mode: mode ? "dark" : "light",
      primary: {
        main: lightBlue[900],
      },
      secondary: {
        main: red[500],
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {user && <Navbar open={open} toggleDrawer={toggleDrawer} title={''} />}
        {user && <Sidebar open={open} toggleDrawer={toggleDrawer} />}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          {/* <Toolbar /> */}

          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layouts;
