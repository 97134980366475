import axios from '../../utils/axios';

// Register user
const register = async (userData) => {
    const response = await axios.post('auth/register', userData)

    if(response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

// Login user
const login = async (userData) => {
    const response = await axios.post('auth/login', userData)

    if(response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

// Logout user
const logout = async () => {
    localStorage.removeItem('user')
}

const authService = {
    register, login, logout
}

export default authService