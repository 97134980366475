import React from "react";
import { useNavigate, useMatch, useResolvedPath } from "react-router-dom";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { getAvataricon } from "../../utils/getAvatarIcon";

export const SingleLevel = ({ item }) => {
  const navigate = useNavigate();
  const [isTo, setIsTo] = React.useState("");

  let resolved = useResolvedPath(isTo);
  let match = useMatch({ path: resolved.pathname, end: true });
  
  function routerHandler(r) {
    setIsTo(r.to);
    navigate(r.to);
  }

  return (
    <React.Fragment>
      <ListItemButton sx={{ height: 42 }} onClick={() => routerHandler(item)}>
        <ListItemIcon sx={{ minWidth: "37px !important" }}>
          {getAvataricon(item.icon)}
        </ListItemIcon>
        <ListItemText
          sx={{ fontSize: "5px !important" }}
          primary={item.title}
        />
      </ListItemButton>
    </React.Fragment>
  );
};
