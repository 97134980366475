import HomeIcon from "@mui/icons-material/Home";
import BedroomChildIcon from "@mui/icons-material/BedroomChild";
import ChairAltIcon from "@mui/icons-material/ChairAlt";
import BathroomIcon from "@mui/icons-material/Bathroom";
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';

export const getAvataricon = (icon) => {
  switch (icon) {
    case 'DashboardCustomizeIcon':
      return <DashboardCustomizeIcon />

    case "HomeIcon":
      return <HomeIcon />;

    case "BedroomChildIcon":
      return <BedroomChildIcon />;

    case "ChairAltIcon":
      return <ChairAltIcon />;

    case 'BathroomIcon':
      return <BathroomIcon />;

    case 'SettingsApplicationsIcon':
      return <SettingsApplicationsIcon />

    case 'ViewListOutlinedIcon':
      return <ViewListOutlinedIcon />

    case 'RocketLaunchOutlinedIcon':
      return <RocketLaunchOutlinedIcon />
      
    case 'ShoppingCartCheckoutOutlinedIcon':
      return <ShoppingCartCheckoutOutlinedIcon />

    case 'CategoryOutlinedIcon':
      return <CategoryOutlinedIcon />

    default:
      return null;
  }
};
