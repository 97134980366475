import * as React from 'react';
import Layouts from "./layouts/layouts";

import { Route, Routes } from 'react-router-dom'
import RequireAuth from "./middleware/requireAuth";

import Login from './containers/login/login'
import { NotFound } from "./containers/not-found/notFound";
import Unauthorized from './containers/unauthorized/unauthorized';
// Protected Routes
import Dashboard from './containers/dashboard/dashboard'
import Settings from './containers/settings/settings';
import About from './containers/about/about';

// KH status containers for demo
import OrderStatus from './containers/order-status'
import ShipmentStatus from './containers/shipment-status'
import SampleStatus from './containers/sample-status'


const ROLES = {
  'USER': 'user',
  'ADMIN': 'admin',
  'SUPERADMIN': 'superadmin'
}

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layouts />}>
       <Route path="login" element={<Login />} />
       <Route path="unauthorized" element={<Unauthorized />} />
       
     
       
       {/* Want to protect these routes */}
       <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/order-status" element={<OrderStatus />} />
          <Route path="/shipment-status" element={<ShipmentStatus />} />
          <Route path="/sample-status" element={<SampleStatus />} />
        </Route>

        {/* <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
          <Route path="/" element={<Dashboard />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.SUPERADMIN]} />}>
          <Route path="/about" element={<About />} />
        </Route> */}

        {/* catch all */}
        <Route path="*" element={<NotFound />} />

      </Route>
    </Routes>
  );
}