import { useNavigate } from "react-router-dom";
import { Container, Grid, Paper, Typography, Button } from "@mui/material";

import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Container maxWidth="sm" sx={{ mt: 10, mb: 4 }}>
      <Paper>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          sx={{ padding: '16px'}}
        >
          <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <PrivacyTipIcon sx={{ fontSize: 80 }}/>
          </Grid>
          <Grid item xs={8}>
            <Typography
              component="h1"
              variant="h4"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Unauthorized
            </Typography>
            <Typography
              component="h5"
              variant="p"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              You do not have access to the requested page.
            </Typography>
            <Button onClick={goBack}>Go Back</Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Unauthorized;
