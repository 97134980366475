export const menu = [
  {
    icon: "DashboardCustomizeIcon",
    title: "Home",
    to: "/",
    items: [],
  },
  {
    icon: "ViewListOutlinedIcon",
    title: "Order Status",
    to: "/order-status",
    items: [],
  },
  {
    icon: "CategoryOutlinedIcon",
    title: "Sample Status",
    to: "/sample-status",
    items: [],
  },
  {
    icon: "ShoppingCartCheckoutOutlinedIcon",
    title: "Shipment Status",
    to: "/shipment-status",
    items: [],
  },
  
  // {
  //   icon: "BedroomChildIcon",
  //   title: "Education",
  //   items: [
  //     {
  //       title: "Technical Analysis",
  //       to: "/about",
  //       items: [],
  //     },
  //     {
  //       title: "Fundamental Analysis",
  //       to: "/about",
  //     },
  //     {
  //       title: "Elliot Wave Analysis",
  //       to: "/about",
  //     },
  //   ],
  // },
  // {
  //   icon: "BathroomIcon",
  //   title: "Masters",
  //   items: [
  //     {
  //       title: "Technical Analysis",
  //       to: "/about",
  //     },
  //     {
  //       title: "Fundamental Analysis",
  //       to: "/about",
  //     },
  //     {
  //       title: "Elliot Wave Analysis",
  //       to: "/about",
  //     },
  //   ],
  // },
  // { icon: "SettingsApplicationsIcon", title: "Settings", to: "/settings" },
  // {
  //   icon: "ChairAltIcon",
  //   title: "About",
  //   to: "/about",
  // },
];
