import React, { useState, useEffect } from "react";
import "./login.css";

import { useSelector, useDispatch } from "react-redux";
import { login, reset } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Grid
} from "@mui/material";

// Material Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoginIcon from '@mui/icons-material/Login';

const initialStates = {
  email: "admin@versamicro.com",
  password: "123456",
  showPassword: false,
}

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

  const [values, setValues] = useState(initialStates);
  // destructuring
  const { email, password, showPassword } = values;

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isSuccess || user) {
      navigate('/')
    }

    dispatch(reset())
  }, [user, isSuccess, navigate, dispatch])

  const onSubmit = e => {
    e.preventDefault();
    const userData = { email, password }
    dispatch(login(userData))
  }

  const card = (
    <React.Fragment>
      <Box
        className="login__card__content"
        component="form"
        sx={{
          "& > :not(style)": { alignItems: 'stretch', lineHeight: 3.5 },
        }}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <CardContent>
          <Typography gutterBottom style={{ textAlign: 'center'}} variant="h6">
            KH Exports India Pvt Ltd.
          </Typography>
        
          
          <div>
            <LoginIcon sx={{ fontSize: '2.3rem', marginBottom: '-10px' }} />
          </div>
          <Typography
            variant="h5"
            component="div"
            className="login__typography__h5"
          >
            Sign in
          </Typography>


          <div className="login__input__email">
            <TextField
              id="user-email"
              label="Email"
              variant="outlined"
              fullWidth
              size="small"
              type="email"
              onChange={handleChange("email")}
              value={email}
            />
          </div>

          <div className="login__input__email">
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>

          </div>


        </CardContent>
        <CardActions>
          <Button
            size="small"
            fullWidth
            variant="contained"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? 'loading...' : ' Sign In'}
          </Button>

        </CardActions>

        <div className={isError ? "errmsg" : "offscreen"} aria-live="assertive">{message}</div>
          
      </Box>
        
    </React.Fragment>
  );

  return (
    <div style={{ height: '100%'}}>
      <div className="login__div">
        <Box sx={{ minWidth: 220 }}>
          <Card variant="outlined" sx={{ alignItems: 'center' }}>
            {card}
          </Card>
        </Box>

        <div className="login__desc">
              <img src="https://naukrirecruiter.naukri.com/profilePic/getpic?pid=1547788684rp2396292_medium4" width="100" alt="KH Export Logo" title="KH Export" />
              <Typography gutterBottom variant="h6">
                Customer Portal
              </Typography>

              {/* <small style={{ fontSize: '12px', color: '#333', marginRight: '1em', marginTop: '0.5em', float: 'right' }}>
                v{process.env.REACT_APP_BUILD_VERSION}
              </small> */}
            </div>
      </div>
    </div>
  );
};

export default Login;
