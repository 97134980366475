// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import appService from './appService';

// const app = JSON.parse(localStorage.getItem('app'))
// const initialState = {
//     mode: app ? app : false,
//     isLoading: false
// }


// export const themeHandler = createAsyncThunk('app/themeHandler', async (d) => {
//     const mode = JSON.parse(localStorage.setItem("app", d))
//     console.log('mode', d)
//     return d
// });

// export const appSlice = createSlice({
//     name: 'app',
//     initialState,
//     reducers: {
//         reset: (state) => {
//             state.isLoading = false
//         }
//     },
//     extraReducers: (builder) => {
//         builder
//             .addCase(themeHandler.pending, (state) => {
//                 state.isLoading = true
//             })
//             .addCase(themeHandler.fulfilled, (state, action) => {
//                 console.log(action)
//                 state.isLoading = false;
//                 state.mode = action.payload
//             })
//     }
// });

// export const { reset } = appService.actions;
// export default appSlice.reducer;


import { createSlice } from '@reduxjs/toolkit'

const app = JSON.parse(localStorage.getItem('app'))
const initialState = { mode: app ? app : false }

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    themeHandler(state, action) {
        localStorage.setItem("app", action.payload)
      state.mode = action.payload
    },
  },
})

export const { themeHandler } = appSlice.actions
export default appSlice.reducer