import { configureStore } from '@reduxjs/toolkit'

// services
import authReducer from '../features/auth/authSlice'
import appReducer from '../features/app/appSlice'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        app: appReducer
    }
});