import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { themeHandler } from "../../features/app/appSlice";
import { CssBaseline, Container, Paper, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Switch } from "@mui/material";

import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import ListIcon from '@mui/icons-material/List';

const Settings = () => {
  console.log("SETTINGS");
  const dispatch = useDispatch()
  const { mode } = useSelector((state) => state.app)

  const [dark, setDark] = React.useState(mode);
  const [checked, setChecked] = React.useState(["mode"]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const toggleThemeMode = (e) => {
    setDark(e.target.checked)
    dispatch(themeHandler(dark))
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm" sx={{ mt: 10, mb: 4 }}>
        <Paper>
          <List
            sx={{ width: "100%", maxWidth: 550, minWidth: 100, bgcolor: "background.paper" }}
            subheader={<ListSubheader>Settings</ListSubheader>}
          >
            <ListItem>
              <ListItemIcon>
              {mode ? <LightModeIcon /> : <DarkModeIcon /> }
              </ListItemIcon>
              <ListItemText id="switch-list-label-mode" primary="Dark Mode" />
              <Switch
                edge="end"
                onChange={toggleThemeMode}
                checked={mode}
                inputProps={{
                  "aria-labelledby": "switch-list-label-mode",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-menu" primary="Menu" />
              <Switch
                edge="end"
                onChange={handleToggle("menu")}
                checked={checked.indexOf("menu") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-menu",
                }}
              />
            </ListItem>
          </List>
        </Paper>
      </Container>
    </React.Fragment>
  );
};

export default Settings;
