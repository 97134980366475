import React from 'react';
import { Container, Grid, Paper } from "@mui/material";
import Typography from '@mui/material/Typography';

const Dashboard = () => {

  return (
  <Container maxWidth="xl" sx={{ mt: 10, mb: 10 }}>
    <Paper>
      <Grid container spacing={2}>
        <Grid item>
          <div style={{ margin: '6px 12px' }}>
            <Typography variant="h6" gutterBottom component="div">
              Dashboard
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Paper>
  </Container>
  );
};

export default Dashboard;
