import React, { useState } from "react";

import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { getAvataricon } from "../../utils/getAvatarIcon";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { MenuItem } from "./menu-item";

export const MultiLevel = ({ item }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ListItemButton sx={{ height: 42 }} onClick={() => handleClick()}>
        <ListItemIcon sx={{ minWidth: "37px !important" }}>
          {getAvataricon(item.icon)}
        </ListItemIcon>
        <ListItemText primary={item.title} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="nav">
          {children?.map((child, key) => (
            <MenuItem item={child} key={key} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};
