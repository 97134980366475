import React from "react";

import MuiDrawer from "@mui/material/Drawer";
import { styled, Toolbar, IconButton, List } from "@mui/material";

// Material Icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { menu } from "../../utils/menu";
import { MenuItem } from "./menu-item";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(6.5),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(6.7),
      },
    }),
  },
  img: {
    width: "100px !important",
    height: "auto",
  },
}));

const Sidebar = ({ open, toggleDrawer }) => {
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: [0.5],
          marginTop: "-0.4rem",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw8QERIQEBAWExIWFxAWGBcYGRUQHA8QFRoWFhUTFRgYIiggGBolHRMVITEhJSkrLi4uGB8zODMsNygtLisBCgoKDg0NGhAPFy0hHx0tLSstLS0tKystNC0tLS0tLS0sLS4rKysuMCstNystKywyNzYtNysuLTcrLS0rKysrK//AABEIAFoAggMBIgACEQEDEQH/xAAbAAEAAgMBAQAAAAAAAAAAAAAABAUBAwYHAv/EADgQAAICAQIEAgYHCAMAAAAAAAECAAMRBBIFISIxEzIGQUJRcoIUUmFicZLwI2NzkaKxssIVQ1P/xAAaAQEAAgMBAAAAAAAAAAAAAAAAAgQBAwUG/8QAHxEBAQACAgIDAQAAAAAAAAAAAAIDEQESBDETIYEF/9oADAMBAAIRAxEAPwD3GIiAiIgIiICImIGYmtXGSuRuHMibICIiAiIgIiICIiAiIgIiICIiBB4jr1pCswJUnBI9mQ9Xq1cJZW2QC3b1TbxKtiGVjuRvs8s5dkehv11Sxix8U0Xk1S40WszqC55A7gZZVavqZmOBjkJQ6ZCGLZBA6gc+b9b5OoHvyffNPk1MelrBHf7XtFm5Q2MZm2Q9PqMkALy/HyyZNc1tGp1yzERJMEREBERAREQEREBNdgbB2kA+ombJj1QOZ19eoB6nLfYp/wBZDVnBO44BDcifN9yX2vrrqUuck+7PmaUOkoa1i57e+WJy6lzcs1xep9pOmQKMkHcS25s8mm01uSSDn4e0KxLEEnq5GOqsgkcv8p5z+jiy5r3NU6njZ+k6TdHTeOxCj7eqWwkbTqcAggqeclS54Pj/ABRrdfvO2brfLMREvoEREBERARE4zTelFzcSfRsEFSmzqw2ehd/m3QOzicRT6UanV6o0aFEapfNa4Zh8XJvyzdwT0lvv192kcIEr8XBAbLbG2/WgdjE5j0347boqkspVWLPtO4M3ss3q/CS14w3hafage+5EbaOkL09T/DAs9TpEsxvGcHIEwunGewCjsJAc65QW/ZPjnsAZc/C0xrOI2404rQK1pwQ4b9n0wj14Zr0hNzjsBzHzSdRQCpVhnn2Mr9XfrKlawil1UZIG5Ttn3quKMSiUIHsdVfqPKtPrNI9OCZ0s6aggCjsJslNbbragXYV2KObKgZTt+5PuzimW03h4KXFsk91wuZLqkt4mu5sKxHcDMpP+WuarTMoQPc2DkNhYF/EpdTdra1Z28F1AyQN6nbLTTXCxFcdmCmBuiIgJ5LreHfSeL6mgOULeLhh/D9v7s9alXXwPSredSKgLjnL5aBw/oLxX6Hc/D9QgRi/S3736r/F7LSv0vC21XFNVUtz0nfed69/N5Z6LxP0e0eocWXUK7gY3ZZTt+WZ0fAdLRY11VWLG3AtuZi274mgedemvo9ZpKkZtXZeGfbtfd09Pm8067hTiptI78lfS1IGPZX2pL3ivCaNSqpfWLFByASy4b5Zt+g1eGtJQFAFAU88KvaBr19NrEGu/w1A5jarbvzSjNj3JomZyHZ7etQufblseA6X/AM+Xu3Pj+8lnQ1dHSBsOUxy2wKXjGgsSprDc9irtLI3Z1+WbKLEr1ZJwFurq8M+ro9mXV1KurKwypGCPfNduhqZBWyAqBgA9WIGdbqkqQu5AUD+c5rTIak0TWDaviWk5/wCvf5Jd08F0ykMKgSO24s+PzSbfQlilXUMp7giB8a69Urd2IChW5znBS3gaBMlDv7juvmlunA9MCD4ecdgSzBflk67TI5UsoJU5X7rQK63gxcFbNRcynuMquf6ZaV1hQFUYAGAJsiAiIgIiICIiAiIgIiICIiAiIgIiICIiAiIgf//Z"
            style={{
              border: "1px solid #5c5656ad",
              padding: "3px 6px",
              borderRadius: 2,
            }}
            alt="logo"
            title="Logo"
          />
          {/* <h3 style={{ marginLeft: "10px" }}>slfdjalsjdfljasldjflasjdflkj</h3> */}
        </div>
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <List component="nav" sx={{ marginTop: "-1rem" }}>
        {menu.map((item, key) => (
          <MenuItem item={item} key={key} />
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
